@tailwind base;
@tailwind components;
@tailwind utilities;
body{
  background-color: #d0e3ff;
}
@layer components {
    
    .btn {
      @apply bg-primary text-sm text-white px-4 py-2 rounded-lg hover:bg-primary/80 transition cursor-pointer;
    }
  }