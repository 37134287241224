/* modal.css */
.modal {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  width: 100%;
  padding: 10px;
  /* margin: 10px 0; */
}

.btn {
  padding: 10px 20px;
  /* margin: 10px; */
  background-color: cyan-900;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
