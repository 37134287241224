

.owl-stage-outer {
  padding: 30px 0;
}

.owl-dots {
  text-align: center;
}
 .owl-dots span {
  position: relative;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: block;
  background: #fff;
  margin: 0 5px;
}
 .owl-dots .active {
  box-shadow: none;
}
 .owl-dots .active span {
  background: #01b0f8;
  box-shadow: none;
  height: 12px;
  width: 12px;
  margin-bottom: -1px;
}
.card {
  background: #fff;
  box-shadow: 0 8px 30px -7px #c9dff0;
  margin: 0 20px;
  padding: 0 10px;
  border-radius: 20px;
  border: 0;
}
.card img {
  max-width: 100px;
  border-radius: 50%;
  margin: 15px auto 0;
  box-shadow: 0 8px 20px -4px #95abbb;
  /* width: 100px; */
  height: 100px;
}

.card p {
  font-size: 18px;
  color: #555;
  padding-bottom: 15px;
}
.owl-item.active {
  opacity: 0.5;
  transition: all 0.3s;
}
.owl-item.center {
  opacity: 1;
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  outline: 0;
}
.owl-carousel .owl-item img {
  display: block;
  width: auto !important;
}